import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './Home.tsx';
import {AppBar, Toolbar, Typography, createTheme, ThemeProvider, CssBaseline, Container} from '@mui/material';

// Custom theme
const theme = createTheme({
    palette: {
        primary: {
            main: '#06362e', // Dark green color
        },
        background: {
            default: '#1a9f80', // Dark green color
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            },
        },
    },
});

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <Container maxWidth="xl">
                    <AppBar position="static">
                        <Toolbar>
                            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Typography variant="h6">Isai Music Player</Typography>
                            </Link>
                        </Toolbar>
                    </AppBar>
                    <Routes>
                        <Route path="/" element={<Home />} />
                    </Routes>
                </Container>
            </Router>
        </ThemeProvider>
    );
};

export default App;
