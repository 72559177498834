import React from 'react';
import { Box } from '@mui/material';
import googlePlayBadge from './google-play-badge.png';

const Home: React.FC = () => {
    return (
        <Box sx={{p: 2 }}>
            <p>
                Isai Music Player is an offline music player app that allows you to enjoy your favorite music even without an internet connection. With a sleek and user-friendly interface, you can organize your music library, create playlists, and listen to high-quality audio on the go.
            </p>
            <a href="https://play.google.com/store/apps/details?id=com.isai.app" target="_blank" rel="noopener noreferrer">
                <img src={googlePlayBadge} alt="Download on Google Play" />
            </a>
        </Box>
    );
};

export default Home;
